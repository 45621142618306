import React, { Component } from 'react'
import Calendar from 'react-calendar';
import '../Kalender.css';
import moment from 'moment';
import { kalenderService } from '../services/kalender.service';

class Kalender extends Component {

    constructor(props) {
        super(props);
        this.onClick = this.props.onClick;
        this.type = this.props.type;
        this.onKalenderClick = this.onKalenderClick.bind(this);
        this.state = {
            date: new Date(),
            verfügbar: [],
            nichtVerfügbar: [],
            gebucht: [],
        }
    }

    /**
     * gets calendar data from database
     */
    componentDidMount = async () => {
        this.update();
    }

    update = async () => {
        this.defineDate(await kalenderService.getAll());
    }

    /**
     * @param data, database calendar information like date and it's current status
     * set states for different status
     */
    defineDate = (data) => {
        let verfügbar = []
        let nichtVerfügbar = []
        let gebucht = []

        data.forEach(({ datum, status }) => {
            if (status === 0) {
                //this.setState(prevState => ({ verfügbar: [...prevState.verfügbar, datum] }));
                verfügbar.push(datum);
            } else if (status === 1) {
                //this.setState(prevState => ({ nichtVerfügbar: [...prevState.nichtVerfügbar, datum] }));
                nichtVerfügbar.push(datum);
            } else if (status === 2) {
                //this.setState(prevState => ({ gebucht: [...prevState.gebucht, datum] }));
                gebucht.push(datum);
            }
        })

        this.setState({verfügbar: verfügbar, nichtVerfügbar: nichtVerfügbar, gebucht: gebucht});
    }

    /**
     * 
     * @param date, current selected date
     * set states of the selected day in calendar
     */
    onKalenderClick = (date) => {
        this.setState({ date });
        this.onClick(date)
    }

    render() {
        const { verfügbar, nichtVerfügbar, gebucht } = this.state
        return (
            <div className="calendar-container">
                <Calendar
                    value={this.state.date}
                    minDate={new Date()}
                    minDetail='month'
                    tileDisabled={({ date }) => {
                        if (this.props.type === "formular") {
                            if (verfügbar.find(x => x === moment(date).format("YYYY-MM-DD"))) {
                                return false;
                            } else {
                                return true;
                            }
                        } else if (this.props.type === "admin") {
                            if (gebucht.find(x => x === moment(date).format("YYYY-MM-DD"))) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        }
                    }}
                    tileClassName={({ date, view }) => {
                        if (date.getTime() === this.state.date.getTime()) {
                            return "calendar-selected"
                        }
                        if (verfügbar.find(x => x === moment(date).format("YYYY-MM-DD"))) {
                            return "calendar-free"
                        }
                        if (nichtVerfügbar.find(x => x === moment(date).format("YYYY-MM-DD"))) {
                            return "calendar-notFree"
                        }
                        if (gebucht.find(x => x === moment(date).format("YYYY-MM-DD"))) {
                            return "calendar-bucht"
                        }
                    }}
                    onChange={this.onKalenderClick}
                />
            </div>
        )
    }
}
export default Kalender;
