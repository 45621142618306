import React, { Component } from 'react';
import Kalender from "./Kalender"
import axios from 'axios';
import { withStyles, Button } from "@material-ui/core";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import "yup-phone";
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Theme from "./Theme";
import moment from 'moment';
import { BACKEND_URL } from '../App'
import { CaptchaTest } from '../components/CaptchaTest';
let theme = createTheme(Theme);

/**
 * textfield styles
 */
const CssTextField = styled(TextField)({
    width: "100%",
    '& label.Mui-focused': {
        color: '#cfcfcf',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#cfcfcf',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#cfcfcf',
        },
        '&:hover fieldset': {
            borderColor: '#cfcfcf',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#cfcfcf',
        },
    },
});
const styles = (theme) => ({
    form: {
        width: "50%",
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    dropdown: {
        backgroundColor: "#DC0000",
        width: "50%"
    }
});

const formValidationSchema =
    Yup.object().shape({
        lehrperson_vorname: Yup.string()
            .required('Vorname ist ein Pflichtfeld'),
        lehrperson_nachname: Yup.string()
            .required('Nachname ist ein Pflichtfeld'),
        lehrperson_telefon: Yup.string()
            .phone("CH", "Bitte eine in der Schweiz gültige Telefonnummer eingeben")
            .required('Telefonnummer ist ein Pflichtfeld'),
        lehrperson_email: Yup.string()
            .email('Diese Adresse ist ungültig')
            .required('E-Mail ist ein Pflichtfeld'),
        organisation_name: Yup.string()
            .required('Name der Organisation ist ein Pflichtfeld'),
        organisation_stufe: Yup.string()
            .required('Wählen Sie ein Stufe aus, dies ist ein Pflichtfeld'),
        organisation_niveau: Yup.string()
            .required('Wählen Sie das Niveau aus, dies ist ein Pflichtfeld'),
        anz_teilnehmer: Yup.number()
            .required('Geben Sie die Anzahl Teilnehmern an, dies ist ein Pflichtfeld'),
        anz_begleitpersonen: Yup.number()
            .required('Geben Sie die Anzahl Begleitpersonen an, dies ist ein Pflichtfeld')
            .min(1, "Bitte geben sie eine Anzahl ein")
    });

export class Formular extends Component {
    constructor(props) {
        super(props);

        this.handleVerification = this.handleVerification.bind(this);
        this.state = {
            error: false,
            errormsg: "",
            date: "",
            isVerified: false,
            captchaState: false,
            andereStufe: false,
            andereNiveau: false,
        };
        this.kalenderRef = React.createRef();
    }

    /**
     *  validationSchema()
     * @param values, form data like name, organisationName...
     * creates database entry
     * sends e-mail with form data
     */
    formSubmit = async (values) => {
        const { date } = this.state
        let body = {
            data: values,
            datum: date,
        }

        const config = {
            headers: {
                'Content-Type': 'application/JSON'
            }
        };

        if (this.state.captchaState === false) {
            alert("Das Captcha wurde nicht bestätigt.");
            return;
        }

        if (body.data.organisation_stufe === "andere" && body.data.andere_stufe === "") {
            alert("Der Name der Organisation wurde nicht angegeben.");
            return;
        }

        if (body.data.organisation_niveau === "andere" && body.data.andere_niveau === "") {
            alert("Das Niveau wurde nicht angegeben.");
            return;
        }

        if (this.state.date === "") {
            alert("Es wurde kein Datum ausgewählt.");
            return;
        }

        try {
            await axios.post(BACKEND_URL + 'buchung/create', body, config);
            alert("Vielen Dank für Ihre Anmeldung. Wir setzen uns schnellstmöglich mit Ihnen in Verbindung.");
            this.setState({date: "", captchaState: false});
        } catch(e) {
            alert("Ein Systemfehler ist aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal oder nehmen Sie mit uns Kontakt auf, falls das Problem weiterhin besteht.");
        }

        this.kalenderRef.current.update();
    }

    /**
     * checks if state is set to true or false
     * true -> user verified
     */
    handleVerification = () => {
        if (this.state.isVerified) {
            console.log("User is verified")
        } else {
            console.log("User is not verified")

        }
    }

    /**
     * 
     * @param date, information about the currently selected date
     * sets state and converts date to usable format
     */
    onClickKalender = (date) => {
        this.setState({ date: moment(date).format("YYYY-MM-DD") })
    }

    changeCaptchaState = childData => {
        this.setState({ captchaState: childData });
    }

    render() {
        const initialValues = {
            lehrperson_vorname: '',
            lehrperson_nachname: '',
            lehrperson_telefon: '',
            lehrperson_email: '',
            organisation_name: '',
            organisation_stufe: '',
            andere_stufe: '',
            organisation_niveau: '',
            andere_niveau: '',
            anz_teilnehmer: 0,
            anz_begleitpersonen: 0,
            bemerkung: '',
            confirmed: false
        }
        const customChange = (e, setFieldValue) => {
            setFieldValue(e.target.id, e.target.value);

            if (e.target.value === 'andere') {
                if (e.target.name === "organisation_stufe") {
                    this.setState({andereStufe: true});
                } else if (e.target.name === 'organisation_niveau') {
                    this.setState({andereNiveau: true});
                }
            } else {
                if (e.target.name === "organisation_stufe") {
                    this.setState({andereStufe: false});
                } else if (e.target.name === 'organisation_niveau') {
                    this.setState({andereNiveau: false});
                }
            }

            if (this.state.andereStufe) {
                if (e.target.name === "andere_stufe") {
                    setFieldValue("organisation_stufe", e.target.value);
                }
            }

            if (this.state.andereNiveau) {
                if (e.target.name === "andere_niveau") {
                    setFieldValue("organisation_niveau", e.target.value);
                }
            }
        }

        return (
            <ThemeProvider theme={theme}>
                <div className="formular">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={formValidationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            setTimeout(() => {
                                this.handleVerification(() => {
                                    console.log("Verification works")
                                })
                                setSubmitting(false);
                                this.formSubmit(values)
                            }, 500);
                        }}
                    >
                        {({
                            submitForm,
                            isSubmitting,
                            setFieldValue,
                            touched,
                            errors, }) => (
                            <Form>
                                <p>Bitte wählen Sie ein Datum für Ihre Besichtigung aus.</p>
                                <div className="kalender">
                                    <Kalender
                                        type={"formular"}
                                        onClick={this.onClickKalender}
                                        ref={this.kalenderRef}
                                    />
                                </div>
                                <br />
                                {errors.lehrperson_vorname && touched.lehrperson_vorname ? (
                                    <div className="error_message">{errors.lehrperson_vorname}</div>
                                ) : null}
                                <CssTextField
                                    fullWidth
                                    id="lehrperson_vorname"
                                    label="Ihr Vorname"
                                    name="lehrperson_vorname"
                                    autoFocus
                                    type="text"
                                    onChange={(e) => customChange(e, setFieldValue)}
                                    required
                                />
                                {errors.lehrperson_nachname && touched.lehrperson_nachname ? (
                                    <div className="error_message">{errors.lehrperson_nachname}</div>
                                ) : null}
                                <CssTextField
                                    fullWidth
                                    id="lehrperson_nachname"
                                    label="Ihr Nachname"
                                    name="lehrperson_nachname"
                                    autoFocus
                                    type="text"
                                    onChange={(e) => customChange(e, setFieldValue)}
                                    required
                                />
                                {errors.lehrperson_telefon && touched.lehrperson_telefon ? (
                                    <div className="error_message">{errors.lehrperson_telefon}</div>
                                ) : null}
                                <CssTextField
                                    fullWidth
                                    id="lehrperson_telefon"
                                    label="Ihre Telefonnummer"
                                    name="lehrperson_telefon"
                                    autoFocus
                                    type="tel"
                                    onChange={(e) => customChange(e, setFieldValue)}
                                    required
                                />
                                {errors.lehrperson_email && touched.lehrperson_email ? (
                                    <div className="error_message">{errors.lehrperson_email}</div>
                                ) : null}
                                <CssTextField
                                    fullWidth
                                    id="lehrperson_email"
                                    label="Ihre E-Mail"
                                    name="lehrperson_email"
                                    autoFocus
                                    type="text"
                                    onChange={(e) => customChange(e, setFieldValue)}
                                    required
                                />
                                {errors.organisation_name && touched.organisation_name ? (
                                    <div className="error_message">{errors.organisation_name}</div>
                                ) : null}
                                <CssTextField
                                    fullWidth
                                    id="organisation_name"
                                    label="Name der Schule / Organisation"
                                    name="organisation_name"
                                    autoFocus
                                    type="text"
                                    onChange={(e) => customChange(e, setFieldValue)}
                                    required
                                />
                                {errors.organisation_stufe && touched.organisation_stufe ? (
                                    <div className="error_message">{errors.organisation_stufe}</div>
                                ) : null}
                                <select
                                    id="organisation_stufe"
                                    label="Stufe der Schule / Organisation"
                                    name="organisation_stufe"
                                    autoFocus
                                    type="text"
                                    className="dropDown"
                                    onChange={(e) => customChange(e, setFieldValue)}
                                    style={{
                                        fontFamily: '"Frutiger Next" sans-serif',
                                        fontSize: "16px",
                                        fontWeight: "1000",
                                        color: "#303030",
                                        textIndent: "5px"
                                    }}
                                    required
                                >
                                    <option value="" label="Stufe der Schule / Organisation *" />
                                    <option value="1. Sekundarstufe" label="1. Sekundarstufe" />
                                    <option value="2. Sekundarstufe" label="2. Sekundarstufe" />
                                    <option value="3. Sekundarstufe" label="3. Sekundarstufe" />
                                    <option value="andere" label="andere"/>
                                </select>
                                {this.state.andereStufe && <CssTextField
                                    fullWidth
                                    border="false"
                                    id="andere_stufe"
                                    placeholder='Stufe der Schule / Organisation'
                                    name="andere_stufe"
                                    autoFocus
                                    onChange={(e) => customChange(e, setFieldValue)}
                                    required
                                />}
                                <br />
                                {errors.organisation_niveau && touched.organisation_niveau ? (
                                    <div className="error_message">{errors.organisation_niveau}</div>
                                ) : null}
                                <select
                                    id="organisation_niveau"
                                    label="Niveau der Schule / Organisation"
                                    name="organisation_niveau"
                                    autoFocus
                                    className="dropDown"
                                    onChange={(e) => customChange(e, setFieldValue)}
                                    style={{
                                        fontFamily: '"Frutiger Next" sans-serif',
                                        fontSize: "16px",
                                        fontWeight: "1000",
                                        color: "#303030",
                                        textIndent: "5px"
                                    }}
                                    required={this.state.andereStufe}
                                >
                                    <option value="" label="Niveau der Schule / Organisation *" />
                                    <option value="Niveau A" label="Niveau A" />
                                    <option value="Niveau B" label="Niveau B" />
                                    <option value="Niveau C" label="Niveau C" />
                                    <option value="andere" label="andere" />
                                </select>
                                { this.state.andereNiveau &&
                                <CssTextField
                                    fullWidth
                                    border="false"
                                    id="andere_niveau"
                                    placeholder='Niveau der Schule / Organisation'
                                    name="andere_niveau"
                                    autoFocus
                                    onChange={(e) => customChange(e, setFieldValue)}
                                    required={this.state.andereNiveau}
                                />}
                                {errors.anz_teilnehmer && touched.anz_teilnehmer ? (
                                    <div className="error_message">{errors.anz_teilnehmer}</div>
                                ) : null}
                                <CssTextField
                                    fullWidth
                                    id="anz_teilnehmer"
                                    label="Anzahl Schüler*innen"
                                    name="anz_teilnehmer"
                                    autoFocus
                                    type="number"
                                    onChange={(e) => customChange(e, setFieldValue)}
                                    required
                                />
                                {errors.anz_begleitpersonen && touched.anz_begleitpersonen ? (
                                    <div className="error_message">{errors.anz_begleitpersonen}</div>
                                ) : null}
                                <CssTextField
                                    fullWidth
                                    id="anz_begleitpersonen"
                                    label="Anzahl Begleitpersonen"
                                    name="anz_begleitpersonen"
                                    autoFocus
                                    type="number"
                                    onChange={(e) => customChange(e, setFieldValue)}
                                    required
                                />
                                {errors.bemerkung && touched.bemerkung ? (
                                    <div className="error_message">{errors.bemerkung}</div>
                                ) : null}
                                <CssTextField
                                    fullWidth
                                    id="bemerkung"
                                    label="Bemerkungen"
                                    name="bemerkung"
                                    autoFocus
                                    type="text"
                                    onChange={(e) => customChange(e, setFieldValue)}
                                />
                                <br />
                                {!this.state.captchaState && <CaptchaTest parentCallback = {this.changeCaptchaState}></CaptchaTest>}
                                {this.state.captchaState &&
                                <Button
                                    id="button-Submit"
                                    type="submit"
                                    variant="contained"
                                    disabled={isSubmitting}
                                >
                                    Formular absenden
                                </Button>
                                }
                            </Form>
                        )}
                    </Formik>
                </div>
            </ThemeProvider>
        )
    }
}
export default withStyles(styles)(Formular);
