import React, { Component } from 'react'
import { Typography } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from "@material-ui/core/styles";
import Kalender from "./Kalender"
import { Button } from "@material-ui/core";
import moment from 'moment';
import { authenticationService } from '../services/authentication.service';
import auth from "../auth";
import axios from 'axios';
import Theme from "./Theme";
import { kalenderService } from "../services/kalender.service";
let theme = createTheme(Theme);

export class Date extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: ""
        }
        this.kalenderRef = React.createRef();

    }

    /**
     * logout
     * redirects user to the login page
     */
    btnLogoutHandler = () => {
        this.props.history.push({
            pathname: '/login'
        });
        authenticationService.logout()
        auth.logout()
    }

    /**
     * redirects user back to the admin page
     */
    btnBackHandler = () => {
        this.props.history.push({
            pathname: '/admin'
        });
    }

    /**
     * @param date, information about the currently selected date
     * sets state and converts date to usable format
     */
    onClickKalender =  (date) => {
        this.setState({ date: moment(date).format("YYYY-MM-DD")})
    }

    /**
     * updates the current selected Date in the Calendar Database
     */
    btnAvailable = async () => {
        console.log("Datum wird geändert")
        await kalenderService.updateCalendar(this.state.date, 0);
        await this.kalenderRef.current.update();
    }

    /**
     * PUT request to the given api
     * @param body, information like the date and the status
     */
    btnNotAvailable = async () => {
        await kalenderService.updateCalendar(this.state.date, 1);
        await this.kalenderRef.current.update();
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <main>
                    <div className="detailPage" align="center">
                        <Typography variant="h2" align="center" color="red" gutterBottom>
                            Kalender bearbeiten
                        </Typography>
                        <Typography variant="h5" align="center" color="gray" >
                            Die Termine können auf Verfügbar oder Nicht Verfügbar gesetzt werden
                        </Typography>
                        <br />
                        <Typography variant="h6" align="center" color="gray" >
                            Um einen Termin zu buchen, auf der Buchungsliste aufs + Icon drücken
                        </Typography>
                        <br />
                        <div className="btnGroup">
                            <Button
                                id="btn"
                                fullWidth
                                variant="contained"
                                style={{ backgroundColor: "#DC0000", color: "white" }}
                                onClick={this.btnLogoutHandler}
                            >
                                Logout
                            </Button>
                            <Button
                                id="btn"
                                fullWidth
                                variant="contained"
                                style={{ backgroundColor: "#DC0000", color: "white" }}
                                onClick={this.btnBackHandler}
                            >
                                Zurück
                            </Button>
                        </div>
                        <br />
                        <div className="kalender" align="center">
                            <Kalender
                                ref={this.kalenderRef}
                                type={"admin"}
                                onClick={this.onClickKalender}
                            />
                        </div>
                        <div className="btnKalender">
                            <Button
                                id="calendarBtn"
                                fullWidth
                                variant="contained"
                                style={{ backgroundColor: "#6ac535" }}
                                onClick={this.btnAvailable}
                            >
                                Verfügbar
                            </Button>
                            <Button
                                id="calendarBtn"
                                fullWidth
                                variant="contained"
                                style={{ backgroundColor: "#808080", color: "white" }}
                                onClick={this.btnNotAvailable}
                            >
                                Nicht Verfügbar
                            </Button>
                        </div>


                    </div>
                </main>
            </ThemeProvider>
        )
    }
}
export default Date;
