import React, { Component } from 'react'
import Formular from './Formular'
import { Typography } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from "@material-ui/core/styles";
import Theme from "./Theme";
let theme = createTheme(Theme);

export class BenutzerOberflaeche extends Component {
    render() {
        return (
            <ThemeProvider theme={theme}>
                <main>
                    <div className="startSeite">
                        {/**
                        <Typography variant="h2" color="red" gutterBottom>
                            Ihre Betriebsbesichtigung
                        </Typography>
                        <Typography variant="h4" color="gray" >
                            Unsere Betriebsbesichtigungen finden jeweils zwischen 08:30 und 11:15 Uhr statt. Die Maximalanzahl von Schüler*innen inkl. Begleitpersonen beträgt 30 Personen.
                        </Typography>
                         */}
                        <Formular />
                    </div>
                </main>
            </ThemeProvider>
        )
    }
}

export default BenutzerOberflaeche;