import jwt_decode from "jwt-decode"

class Auth {
    constructor() {
        this.authenticated = false;
        this.logout = this.logout.bind(this);
    }

    /**
     * checks if localStorage Access Token is expired
     */
    login(cb) {
        const token = localStorage.getItem('currentUser');
        var decoded = jwt_decode(token);
        var dateNow = new Date();

        if (decoded.exp < dateNow.getTime())

        this.authenticated = true;
        cb();
    }

    logout() {
        this.authenticated = false;
        localStorage.clear();
    }

    isAuthenticated() {
        return this.authenticated;
    }
}

export default new Auth();