import React, { Component } from 'react';
import auth from "../auth";
import { userService } from '../services/user.service';
import { authenticationService } from '../services/authentication.service';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from "@material-ui/core/styles";
import { Button, LinearProgress, withStyles } from "@material-ui/core";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Theme from "./Theme";
import { styled } from '@mui/material/styles';
import TextField from "@material-ui/core/TextField";
import { Typography } from '@material-ui/core';
let theme = createTheme(Theme);

/**
 * Textfield styles
 */
const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#DC0000',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#DC0000',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'red',
        },
        '&:hover fieldset': {
            borderColor: 'yellow',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#DC0000',
        },
    },
});

const styles = (theme) => ({
    form: {
        width: "100%",
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

const style = {
    backgroundColor: "#DC0000",
    border: "hidden",
    marginTop: "30px",
}

export class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
            errormsg: "",
        };
    }

    validationSchema() {
        return Yup.object().shape({
            username: Yup.string()
                .required('Username is required')
                .min(3, 'Username must be at least 5 characters')
                .max(7, 'Username must not exceed 7 characters'),
            password: Yup.string()
                .required('Password is required')
                .min(6, 'Password must be at least 6 characters')
                .max(40, 'Password must no exceed 40 characters'),
        });
    }

    render() {
        const initialValues = {
            username: '',
            password: '',
        }

        const fieldChange = (e, setFieldValue) => {
            setFieldValue(e.target.id, e.target.value)
        }

        return (
            <ThemeProvider theme={theme}>
                <main>
                    <div className="loginForm">
                        <Typography variant="h2" align="center" gutterBottom>
                            Login
                        </Typography>
                        <Formik
                            className="formikForm"
                            initialValues={initialValues}
                            onSubmit={(values, { setSubmitting }) => {
                                setTimeout(() => {
                                    setSubmitting(false);
                                    authenticationService
                                        .login(values.username, values.password)
                                        .then((user) => {
                                            auth.login(() => {
                                                this.props.history.push("/admin");
                                                userService.getUserBoard();
                                            })
                                        })
                                }, 500);
                            }}
                        >
                            {({
                                submitForm,
                                isSubmitting,
                                setFieldValue,
                                touched,
                                errors, }) => (
                                <Form>
                                    <CssTextField
                                        required
                                        fullWidth
                                        id="username"
                                        label="username"
                                        name="username"
                                        autoFocus
                                        type="text"
                                        onChange={(e) => fieldChange(e, setFieldValue)}
                                    />
                                    {errors.username && touched.username ? (
                                        <div>{errors.username}</div>
                                    ) : null}
                                    <CssTextField
                                        required
                                        fullWidth
                                        id="password"
                                        label="password"
                                        name="password"
                                        type="password"
                                        onChange={(e) => fieldChange(e, setFieldValue)}
                                    />
                                    {errors.password && touched.password ? (
                                        <div>{errors.password}</div>
                                    ) : null}
                                    {this.state.error ? <div>{this.state.errormsg}</div> : null}
                                    {isSubmitting && <LinearProgress />}

                                    <Button
                                        type="submit"
                                        fullWidth
                                        style={style}
                                        variant="contained"
                                        disabled={isSubmitting}
                                    >
                                        Einloggen
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </main>
            </ThemeProvider>
        )
    }
}
export default withStyles(styles)(Login);
