import React, { Component } from 'react'
import { Typography, Table, TableCell, TableContainer, TableRow, Paper, TableBody } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { authenticationService } from '../services/authentication.service';
import auth from "../auth";
import Theme from "./Theme";
import { buchungService } from '../services/buchung.service';
let theme = createTheme(Theme);
export class Detail extends Component {
    constructor(props) {
        super(props);
        this.value = this.props.value;
        this.state = {
            id: this.props.location.state.buchung_id,
            datum: this.props.location.state.datum,
            buchung: []
        }
    }

    /**
     * id = selected buchung id
     * datum = selected date
     * gets Buchung from Database where id = id
     */
    componentDidMount = async () => {
        this.setState({ buchung: await buchungService.getOne(this.props.location.state.buchung_id)})
    }

    /**
     * logout
     * redirects user to the login page
     */
    btnLogoutHandler = () => {
        this.props.history.push({
            pathname: '/login'
        });
        authenticationService.logout()
        auth.logout()
    }

    /**
     * redirects user back to the admin page
     */
    btnBackHandler = () => {
        this.props.history.push({
            pathname: '/admin'
        });
    }

    /**
     * deletes the current Buchung
     * DELETE Request to the Backend with the current selected Buchung ID
     */
    btnDeleteHandler = async () => {
        console.log("Buchung wird gelöscht")
        await buchungService.deleteById(this.props.location.state.buchung_id);
        this.props.history.push({
            pathname: '/admin'
        });
    }

    /**
     * Accept current selected Buchung
     * PUT Request to the Backend with the current selected Buchung ID
     */
    btnAcceptHandler = async () => {
        console.log("Buchung wird bestätigt")
        await buchungService.accept(this.props.location.state.buchung_id);
        this.props.history.push({
            pathname: '/admin'
        });
    }

    render() {
        const buchung = this.state.buchung;
        return (
            <ThemeProvider theme={theme}>
                <main>
                    <div className="detailPage" align="center">
                        <Typography variant="h2" align="center" color="red" gutterBottom>
                            Buchung
                        </Typography>
                        <div className="btnGroup">
                            <Button
                                id="btnn"
                                fullWidth
                                variant="contained"
                                style={{ backgroundColor: "#DC0000", color: "white" }}
                                onClick={this.btnLogoutHandler}
                            >
                                Logout
                            </Button>
                            <Button
                                id="btn"
                                fullWidth
                                variant="contained"
                                style={{ backgroundColor: "#DC0000", color: "white" }}
                                onClick={this.btnAcceptHandler}
                            >
                                Bestätigen
                            </Button>
                            <Button
                                id="btn"
                                fullWidth
                                variant="contained"
                                style={{ backgroundColor: "#DC0000", color: "white" }}
                                onClick={this.btnDeleteHandler}
                            >
                                Löschen
                            </Button>
                            <Button
                                id="btn"
                                fullWidth
                                variant="contained"
                                style={{ backgroundColor: "#DC0000", color: "white" }}
                                onClick={this.btnBackHandler}
                            >
                                Zurück
                            </Button>
                        </div>
                        <TableContainer component={Paper}>
                            <Table className="detailTable" aria-label="simple table">
                                <TableBody>
                                    <TableRow className="detailTableHead">
                                        <TableCell variant="head">Datum</TableCell>
                                        <TableCell align="right">{(new Date(this.state.datum)).toLocaleDateString("de-CH")}</TableCell>
                                    </TableRow>
                                    <TableRow className="detailTableHead">
                                        <TableCell variant="head">Organisation Name</TableCell>
                                        <TableCell align="right">{buchung.organisation_name}</TableCell>
                                    </TableRow>
                                    <TableRow className="detailTableHead">
                                        <TableCell variant="head">Stufe</TableCell>
                                        <TableCell align="right">{buchung.organisation_stufe}</TableCell>
                                    </TableRow>
                                    <TableRow className="detailTableHead">
                                        <TableCell variant="head">Niveau</TableCell>
                                        <TableCell align="right">{buchung.organisation_niveau}</TableCell>
                                    </TableRow>
                                    <TableRow className="detailTableHead">
                                        <TableCell variant="head">Anzahl Teilnehmer</TableCell>
                                        <TableCell align="right">{buchung.anz_teilnehmer}</TableCell>
                                    </TableRow>
                                    <TableRow className="detailTableHead">
                                        <TableCell variant="head">Anzahl Begleitpersonen</TableCell>
                                        <TableCell align="right">{buchung.anz_begleitpersonen}</TableCell>
                                    </TableRow>
                                    <TableRow className="detailTableHead">
                                        <TableCell variant="head">Verantwortliche Lehrperson</TableCell>
                                        <TableCell align="right">{buchung.lehrperson_vorname} {buchung.lehrperson_nachname}</TableCell>
                                    </TableRow>
                                    <TableRow className="detailTableHead">
                                        <TableCell variant="head">Telefon</TableCell>
                                        <TableCell align="right">{buchung.lehrperson_telefon}</TableCell>
                                    </TableRow>
                                    <TableRow className="detailTableHead">
                                        <TableCell variant="head">E-Mail</TableCell>
                                        <TableCell align="right">{buchung.lehrperson_email}</TableCell>
                                    </TableRow>
                                    <TableRow className="detailTableHead">
                                        <TableCell variant="head">Bemerkung</TableCell>
                                        <TableCell align="right">{buchung.bemerkung}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </main>
            </ThemeProvider>
        )
    }
}
export default Detail;
