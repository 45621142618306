import React from "react";
import { Route, Redirect } from "react-router-dom";

export default function ProtectedRoutes({ component: Component, ...rest }) {
    return (

        /**
         * checks, if a user token is saved in the localstorage
         * if not -> redirects user to login
         * else redirects user to the page he selected
         */
        <Route
            {...rest}
            render={(props) => {
                const token = localStorage.getItem("currentUser");
                if (token) {
                    return <Component {...props} />;
                } else {
                    return (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: {
                                    from: props.location,
                                },
                            }}
                        />
                    );
                }
            }}
        />
    );
}