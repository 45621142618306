export default function authHeader() {
    const user = JSON.parse(localStorage.getItem("currentUser"));
    /**
     * Returns acces token from local storage
     */
    if (user) {
        return { Authorization: "Bearer " + user.access_token };
    } else {
        return {};
    }
}