import { authenticationService } from "../services/authentication.service";

/**
 * 
 * @param response, response from the login backend
 * checks if response is 401, 403 unauthorized
 * @returns data
 */
export function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if ([401, 403].indexOf(response.status) !== -1) {
                authenticationService.logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    })
}