import React, { Component } from 'react'
import { Typography } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { authenticationService } from '../services/authentication.service';
import auth from "../auth";
import Formular from './Formular';
import Theme from "./Theme";
let theme = createTheme(Theme);

export class NeueBuchung extends Component {

    /**
     * logout
     * deletes localstorage and redirects to login page
     */
    btnLogoutHandler = () => {
        this.props.history.push({
            pathname: '/login'
        });
        authenticationService.logout()
        auth.logout()
    }

    /**
     * redirects user to the admin page
     */
    btnBackHandler = () => {
        this.props.history.push({
            pathname: '/admin'
        });
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <main>
                    <div className="detailPage" align="center">
                        <Typography variant="h2" align="center" color="red" gutterBottom>
                            Neue Buchung
                        </Typography>
                        <div className="btnGroup">
                            <Button
                                id="btnn"
                                fullWidth
                                variant="contained"
                                style={{ backgroundColor: "#DC0000", color: "white" }}
                                onClick={this.btnLogoutHandler}
                            >
                                Logout
                            </Button>
                            <Button
                                id="btn"
                                fullWidth
                                variant="contained"
                                style={{ backgroundColor: "#DC0000", color: "white" }}
                                onClick={this.btnBackHandler}
                            >
                                Zurück
                            </Button>
                        </div>
                        <Formular />
                    </div>
                </main>
            </ThemeProvider>
        )
    }
}
export default NeueBuchung;
