import React from "react";
import { Route, BrowserRouter, Switch as Router } from 'react-router-dom';
import './App.css';
import BenutzerOberflaeche from './components/BenutzerOberflaeche';
import Login from "./components/Login";
import Admin from "./components/Admin";
import Detail from "./components/Detail";
import ProtectedRoutes from "./components/ProtectedRoutes";
import NeueBuchung from "./components/NeueBuchung";
import Settings from "./components/Settings";
import Date from "./components/Date";

export const BACKEND_URL = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? process.env.REACT_APP_SERVER_URL : "/";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Router>
            <Route path="/" exact component={BenutzerOberflaeche} />
            <Route path="/login" exact component={Login} />
            <ProtectedRoutes path="/admin" exact component={Admin} />
            <ProtectedRoutes path="/detail" exact component={Detail} />
            {/*Not needed, same as "/": <ProtectedRoutes path="/neueBuchung" exact component={NeueBuchung} />*/}
            <ProtectedRoutes path="/settings" exact component={Settings} />
            <ProtectedRoutes path="/date" exact component={Date} />
        </Router>
      </BrowserRouter>
    </div>
  );
}
export default App;
