import axios from "axios";
import authHeader from "../helpers/auth-header";
import { BACKEND_URL } from '../App'

export const buchungService = {
  getAllBuchung,
  getOne,
  deleteById,
  accept,
};

async function getAllBuchung() {
  return await axios
    .get(BACKEND_URL + "buchung/getAll/", { headers: authHeader() })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("Error getting Kalender Items: " + error);
    });
};

async function getOne(id) {
  return await axios
    .get(BACKEND_URL + `buchung/getOne/${id}`, { headers: authHeader() })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("Error: " + error)
    });
};

async function deleteById(id) {
  return await axios
    .delete(BACKEND_URL + `buchung/delete/${id}`, { headers: authHeader() })
    .then((response) => {
      console.log("Buchung wurde gelöscht")
      return response.data;
    })
    .catch((error) => {
      console.log("Error: " + error)
    });
};

async function accept(id) {
  let data = {
    confirmed: true,
    buchung_id: id
  }
  return await axios
    .put(BACKEND_URL + `buchung/accept`, data, { headers: authHeader() })
    .then((response) => {
      console.log("Buchung wurde gelöscht")
      return response.data;
    })
    .catch((error) => {
      console.log("Error: " + error)
    });
}
