import axios from "axios";
import authHeader from "../helpers/auth-header";
import { BACKEND_URL } from '../App'

export const userService = {
    getPublicContent,
    getUserBoard,
    getModeratorBoard,
    getAdminBoard,
    changePw
}

function getPublicContent() {
    return axios.get(BACKEND_URL + 'all')
}

/**
 * gets User Information from Database
 * @returns 
 */
function getUserBoard() {
    return axios.get(BACKEND_URL + 'user/get', {
        headers: authHeader()
    })
        .then((res) => {
            console.log(res.data)
        })
        .catch((error) => {
            console.log("Error getting User info in user.Service")
            console.error(error)
        })
}

function getModeratorBoard() {
    return axios.get(BACKEND_URL + 'mod', { headers: authHeader() });
}

function getAdminBoard() {
    return axios.get(BACKEND_URL + 'admin', { headers: authHeader() });
}

async function changePw(pw) {
    let data = {
        passwort: pw
    }

    return await axios
        .post(BACKEND_URL + "user/changePw", data, { headers: authHeader() })
        .then(response => {
            console.log("Passwort wurde aktualisiert")
            alert("Das Passwort wurde erfolgreich geändert.");
        })
        .catch(error => {
            console.log("Error: " + error)
            alert("Fehler beim ändern des Passworts.")
        })
};