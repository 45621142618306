import { BehaviorSubject } from 'rxjs';
import { handleResponse } from '../helpers/handle-response';
import { BACKEND_URL } from '../App'


const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() {
        return currentUserSubject.value
    }
}

/**
 * 
 * @param username, username from user in login form
 * @param password, password from user in login form
 * local Storage set Item access Token
 * @returns 
 */
function login(username, password) {
    const config = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch(BACKEND_URL + "auth/login", config)
        .then(handleResponse)
        .then(user => {
            console.log("Service Login in")
            localStorage.setItem('currentUser', JSON.stringify(user));
            currentUserSubject.next(user);
            return user;
        });
}

/**
 * Deletes currentUser from LocalStorage
 */
function logout() {
    localStorage.removeItem('currentUser');
    currentUserSubject.next(null);
}