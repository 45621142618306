import React, { Component } from 'react'
import { Typography } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { Formik, Form } from 'formik';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { authenticationService } from '../services/authentication.service';
import auth from "../auth";
import Theme from "./Theme";
import { einstellungService } from '../services/einstellung.service';
import { userService } from '../services/user.service';
let theme = createTheme(Theme);

/**
 * textfield styles
 */
const CssTextField = styled(TextField)({
    margin: "20px",
    '& label.Mui-focused': {
        color: '#DC0000',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#DC0000',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#DC0000',
        },
        '&:hover fieldset': {
            borderColor: '#DC0000',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#DC0000',
        },
    },
});

export class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            settings: {
                einstellung_id: "",
                admin_mail: "",
            },
        }
    }

    /**
     * gets setting data from database
     */
    componentDidMount = async () => {
        this.setState({settings : await einstellungService.get()});
    }

    /**
     * logout 
     * redirects user to login page
     */
    btnLogoutHandler = () => {
        this.props.history.push({
            pathname: '/login'
        });
        authenticationService.logout()
        auth.logout()
    }

    /**
     * redirects user to admin page
     */
    btnBackHandler = () => {
        this.props.history.push({
            pathname: '/admin'
        });
    }

    /**
     * 
     * @param values, information about the new email adress
     * updates the database with the new email adress
     */
    changeMail = async (values) => {
        const { settings } = this.state
        console.log("Adminadresse wird aktualisiert")
        await einstellungService.change(values, settings.einstellung_id);
        //this.setState({settings: {...this.state.settings, admin_mail: values.email}});
    }

    render() {
        const initialValues = {
            email: ''
        }

        const customChange = (e, setFieldValue) => {
            setFieldValue(e.target.id, e.target.value)
        }

        const { admin_mail } = this.state.settings
        return (
            <ThemeProvider theme={theme}>
                <main>
                    <div className="detailPage" align="center">
                        <Typography variant="h2" align="center" color="red" gutterBottom>
                            Einstellungen
                        </Typography>
                        <Typography variant="h5" align="center" color="gray" >
                            Aktuelle Adminadresse beim Absenden eines Formulars:
                        </Typography>
                        <br />
                        <Typography variant="h7" align="center" color="gray" >
                            {admin_mail}
                        </Typography>
                        <br />
                        <br />
                        <Formik
                            initialValues={initialValues}
                            //validationSchema={this.validationSchema}
                            onSubmit={(values, { setSubmitting }) => {
                                //setTimeout(() => {
                                    this.changeMail(values)
                                //}, 500)
                            }}
                        >
                            {({
                                submitForm,
                                isSubmitting,
                                setFieldValue,
                                touched,
                                errors, }) => (
                                <Form>
                                    <CssTextField
                                        required
                                        fullWidth
                                        id="email"
                                        label="Neue Admin E-Mail"
                                        name="email"
                                        autoFocus
                                        type="text"
                                        onChange={(e) => customChange(e, setFieldValue)}
                                    />
                                    {errors.email && touched.email ? (
                                        <div>{errors.email}</div>
                                    ) : null}


                                    <div className="btnGroup">
                                        <Button
                                            id="btn"
                                            fullWidth
                                            type="submit"
                                            variant="contained"
                                            disabled={isSubmitting}
                                            style={{ backgroundColor: "#DC0000", color: "white" }}
                                        >
                                            Bestätigen
                                        </Button>
                                    </div>

                                </Form>
                            )}
                        </Formik>
                        <br />
                        <br />
                        <Formik
                            initialValues={initialValues}
                            //validationSchema={this.validationSchema}
                            onSubmit={(values, { setSubmitting }) => {
                                    userService.changePw(values.password);
                            }}
                        >
                            {({
                                submitForm,
                                isSubmitting,
                                setFieldValue,
                                touched,
                                errors, }) => (
                                <Form>
                                    <CssTextField
                                        required
                                        fullWidth
                                        id="password"
                                        label="Neues Passwort"
                                        name="password"
                                        autoFocus
                                        type="password"
                                        onChange={(e) => customChange(e, setFieldValue)}
                                    />
                                    {errors.password && touched.password ? (
                                        <div>{errors.password}</div>
                                    ) : null}


                                    <div className="btnGroup">
                                        <Button
                                            id="btn"
                                            fullWidth
                                            type="submit"
                                            variant="contained"
                                            disabled={isSubmitting}
                                            style={{ backgroundColor: "#DC0000", color: "white" }}
                                        >
                                            Bestätigen
                                        </Button>
                                    </div>

                                </Form>
                            )}
                        </Formik>

                        <div className="btnGroup">
                                        <Button
                                            id="btn"
                                            fullWidth
                                            variant="contained"
                                            style={{ backgroundColor: "#DC0000", color: "white" }}
                                            onClick={this.btnLogoutHandler}
                                        >
                                            Logout
                                        </Button>
                                        <Button
                                            id="btn"
                                            fullWidth
                                            variant="contained"
                                            style={{ backgroundColor: "#DC0000", color: "white" }}
                                            onClick={this.btnBackHandler}
                                        >
                                            Zurück
                                        </Button>
                                    </div>
                    </div>
                </main>
            </ThemeProvider>
        )
    }
}
export default Settings;
