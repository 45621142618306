import axios from "axios";
import authHeader from "../helpers/auth-header";
import { BACKEND_URL } from '../App'


export const einstellungService = {
    change,
    get
};

async function change(mail, id) {
    let data = {
        admin_mail: mail,
        einstellung_id: id
    }

    return await axios
        .put(BACKEND_URL + "einstellung/change", data, { headers: authHeader() })
        .then(response => {
            console.log("Adminadresse wurde aktualisiert")
        })
        .catch(error => {
            console.log("Error: " + error)
        })
};

async function get() {
    return await axios
        .get(BACKEND_URL + "einstellung/get", { headers: authHeader() })
        .then(response => {
            return response.data[0];
        })
        .catch(error => {
            console.log("Error: " + error)
        })
};
