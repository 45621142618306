const Theme = {
    palette: {
        white: '#ffffff',
        red: '#DC0000',
        black: '#191919',
        gray: '#706F6F',
    },
    typography: {
        h2: {
            fontSize: 54,
        },
        h4: {
            fontSize: 30,
        },
        h7: {
            fontSize: 21,
        },
        h8: {
            fontSize: 21,
            lineHeight: 3,
        }
    },
};
export default Theme;
