import React, { Component } from 'react';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { Formular } from './Formular';
import { Form } from 'formik';

const CssTextField = styled(TextField)({
    width: "100%",
    '& label.Mui-focused': {
        color: '#cfcfcf',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#cfcfcf',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#cfcfcf',
        },
        '&:hover fieldset': {
            borderColor: '#cfcfcf',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#cfcfcf',
        },
    },
});

export class CaptchaTest extends Component {

   componentDidMount(props) {
      loadCaptchaEnginge(6); 
   };

    sendData = (state) => { 
        this.props.parentCallback(state);
    };

   doSubmit = () => {
        let user_captcha = document.getElementById('user_captcha_input').value;

        if (validateCaptcha(user_captcha)===true) {
            this.sendData(true);
            //alert('Captcha Matched');
            document.getElementById('user_captcha_input').value = "";
        }

        else {
            this.sendData(false);
            alert("Captcha war nicht korrekt.");
            document.getElementById('user_captcha_input').value = "";
       }
   };

   render() {
       return (<div>
           <div className="container">
               <div className="form-group">
                   <div className="col mt-3">
                       <LoadCanvasTemplate />
                   </div>

                   <div className="col mt-3">
                       <div><CssTextField placeholder="Captcha-Text eingeben" id="user_captcha_input" name="user_captcha_input" type="text"></CssTextField></div>
                   </div>

                   <div className="col mt-3">
                       <div><button type="button" className="captchaButton" onClick={() => this.doSubmit()}>Captcha bestätigen</button></div>
                   </div>
                     
               </div>

           </div>
       </div>);
   };
}

export default CaptchaTest;