import React, { Component } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { Add, MoreHoriz } from "@mui/icons-material";
import { authenticationService } from "../services/authentication.service";
import auth from "../auth";
import Theme from "./Theme";
import { buchungService } from "../services/buchung.service"
let theme = createTheme(Theme);

export class Admin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      buchung: [],
    };
  }

  /**
   * Get request to the given api
   */
  componentDidMount = async () => {
    this.setState({buchung: await buchungService.getAllBuchung()});
  };

  /**
   * Redirects user to the detail page
   * @param id, id of the selected Buchung
   * @param datum, date of the selected Buchung
   */
  btnDetailHandler = (id, datum) => {
    this.props.history.push({
      pathname: "/detail",
      state: { buchung_id: id, datum: datum },
    });
  };

  /**
   * Redirects user to the given Route
   */
  btnAddHandler = () => {
    this.props.history.push({
      pathname: "/",
    });
  };

  /**
   * Logout
   * Redirects User to the Login Page
   */
  btnLogoutHandler = () => {
    this.props.history.push({
      pathname: "/login",
    });
    authenticationService.logout();
    auth.logout();
  };

  /**
   * Redirects User to the given Route (Settings)
   */
  btnMailHandler = () => {
    console.log("Settings werden geöffnet");
    this.props.history.push({
      pathname: "/settings",
    });
  };

  /**
   * Redirects User to the given Route (edit Calendar)
   */
  btnDateHandler = () => {
    console.log("Kalender wird geöffnet");
    this.props.history.push({
      pathname: "/date",
    });
  };

  /**
   *
   * @param status, status of the current selected Date
   * @returns string
   */
  getStatus = (status) => {
    if (status) {
      return "bestätigt";
    } else {
      return "nicht bestätigt";
    }
  };

  render() {
    return (
      <ThemeProvider theme={theme}>
        <main>
          <div className="adminSeite">
            <Typography variant="h2" align="center" color="red" gutterBottom>
              Buchungen
            </Typography>
            <br />
            <div className="adminSeiteContent">
              <div className="btnGroup">
                <Button
                  id="btnn"
                  fullWidth
                  variant="contained"
                  style={{ backgroundColor: "#DC0000", color: "white" }}
                  onClick={this.btnLogoutHandler}
                >
                  Logout
                </Button>
                <Button
                  id="btn"
                  fullWidth
                  variant="contained"
                  style={{ backgroundColor: "#DC0000", color: "white" }}
                  onClick={this.btnMailHandler}
                >
                  Einstellungen
                </Button>
                <Button
                  id="btn"
                  fullWidth
                  variant="contained"
                  style={{ backgroundColor: "#DC0000", color: "white" }}
                  onClick={this.btnDateHandler}
                >
                  Kalender Bearbeiten
                </Button>
              </div>
              <div className="buchungTable">
                <TableContainer component={Paper}>
                  <Table
                    sx={{ maxWidth: 1000 }}
                    aria-label="simple table"
                    align="center"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Datum</TableCell>
                        <TableCell>Organisation Name</TableCell>
                        <TableCell>Teilnehmer</TableCell>
                        <TableCell>Verantwortliche Person</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>
                          <Button id="btnAdd" onClick={this.btnAddHandler}>
                            <Add />
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.buchung.map((el) => (
                        <TableRow
                          key={el.buchung_id}
                          sx={{
                            "&:last-child td, 6:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {(new Date(el.fk_kalender.datum)).toLocaleDateString("de-CH")}
                          </TableCell>
                          <TableCell>{el.organisation_name}</TableCell>
                          <TableCell>{el.anz_teilnehmer}</TableCell>
                          <TableCell>
                            {el.lehrperson_vorname} {el.lehrperson_nachname}
                          </TableCell>
                          <TableCell>{this.getStatus(el.confirmed)}</TableCell>
                          <TableCell>
                            <Button
                              id="btnDetail"
                              onClick={() => {
                                this.btnDetailHandler(
                                  el.buchung_id,
                                  el.fk_kalender.datum
                                );
                              }}
                            >
                              <MoreHoriz />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
            
          </div>
        </main>
      </ThemeProvider>
    );
  }
}

export default Admin;
