import axios from "axios";
import authHeader from "../helpers/auth-header";
import { BACKEND_URL } from '../App'

export const kalenderService = {
    updateCalendar,
    getAll
};

async function updateCalendar(datum, status) {
    let data = {
        datum: datum,
        status: status
    }

    return await axios
        .put(BACKEND_URL + "kalender/update", data, { headers: authHeader() })
        .then(response => {
            console.log("Datum wurde geändert")
        })
        .catch(error => {
            console.log("Error: " + error)
        })
};

async function getAll() {
    return await axios
        .get(BACKEND_URL + "kalender/getAll", { headers: authHeader() })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            console.log("Error: " + error)
        })
};
